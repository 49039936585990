/* Base */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import getConfig from "next/config";
/* Utilities */
import fetch from "isomorphic-unfetch";
import paths from "utils/paths";
/* Styles */
import media from "styles/media";
import spacing from "styles/spacing";
import fontSizes from "styles/fontSizes";
import { black70, gray05, white00 } from "styles/colors";
/* Constants */
import { HEADER_PROPS_FROM_API } from "constants/propTypes";
/* Components */
import Head from "next/head";
import Link from "next/link";
import Button from "components/Button";
import PageWrapper from "components/layout/PageWrapper";

const { publicRuntimeConfig } = getConfig();

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: ${gray05};
  padding: ${spacing[5]} ${spacing[4]} ${spacing[5]};
  ${media.large`
    padding-left: ${spacing[5]};
    padding-right: ${spacing[5]};
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Content = styled.div`
  background-color: ${white00};
  color: ${black70};
  max-width: 57rem;
  box-sizing: border-box;
  box-shadow: rgba(51, 51, 51, 0.18) 0px 0px 8px;
  margin: 0 auto;
  margin-bottom: ${spacing[6]};
  padding: ${spacing[4]};
  padding-bottom: ${spacing[5]};
  ${media.large`
    padding: ${spacing[5]};
  `};
  text-align: center;
`;

const H1 = styled.h1`
  margin-top: 0;
  margin-bottom: ${spacing[4]};
  ${fontSizes[5]};
  & > small {
    ${fontSizes[3]};
  }
  ${media.large`
    ${fontSizes[7]};
    line-height: 1;
    & > small {
      ${fontSizes[5]};
    }
  `};
`;

// Check environment
const branchName = publicRuntimeConfig?.BRANCH_NAME || "";

// Check if we're running on the server in non-sandbox environments
const isServer = typeof window === "undefined";
const shouldRunNewRelic =
  isServer && branchName && !branchName.startsWith("sand");

// Import New Relic on the server side
let newrelic;
if (shouldRunNewRelic) {
  try {
    // eslint-disable-next-line global-require
    newrelic = require("newrelic");
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      "New Relic agent could not be loaded. Server-side monitoring may be affected:",
      e
    );
  }
}

// TODO: improve this page as it was just quickly modified for GTS-1298
const Error = ({ header, statusCode }) => (
  <PageWrapper items={header}>
    <Head>
      <title>{statusCode ? `${statusCode} - Error` : "Error"}</title>
    </Head>
    <Wrapper>
      <ContentContainer>
        <Content>
          <H1>
            {statusCode ? `Error: ${statusCode}` : "Oops!"}
            <br />
            <small className="fw-normal">
              Looks like something didn’t go as planned.
            </small>
          </H1>
          <p className="f3 mt3">
            <br /> This could be a temporary glitch or the page you’re looking
            for might have moved.
            <br /> Let’s get you back on track!
            <br className="ml-hide lg-hide" /> Here are some places you can go:
          </p>
          <nav className="f3 mb5">
            <Link href={paths.search().canonicalUrl} className="block">
              Search for cruises
            </Link>
            <Link href={paths.about().canonicalUrl} className="block">
              Learn more about us
            </Link>
            <a href="mailto:contact@gotosea.com" className="block">
              Contact us
            </a>
          </nav>

          <Link href={paths.home().canonicalUrl}>
            <Button>Go Home</Button>
          </Link>
        </Content>
      </ContentContainer>
    </Wrapper>
  </PageWrapper>
);

Error.getInitialProps = async (ctx) => {
  const { err } = ctx;

  try {
    const url = `${publicRuntimeConfig?.POSEIDON_NEXT_API || ""}/error_page/`;
    const res = await fetch(url);
    if (res.status === 200) {
      return res.json();
    }
    // eslint-disable-next-line no-console
    console.error(
      `Error fetching error page data: Status ${res.status}`,
      await res.text()
    );
    return { error: res.status };
  } catch (e) {
    // Log error to New Relic if we are on the server
    if (shouldRunNewRelic && newrelic) {
      newrelic.noticeError(err || e); // Log either server error or fetch error
    }

    return {
      statusCode: err?.statusCode || 500,
      message: err?.message || e.message,
      stack: err?.stack || e.stack,
    };
  }
};

Error.propTypes = {
  header: HEADER_PROPS_FROM_API,
  statusCode: PropTypes.number,
};

export default Error;
